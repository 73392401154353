import React from "react";
import FirstSection from "../FirstSection/FirstSection";
import useScroll from "../../custom-hooks/useScroll";
import UnfoldingItem from "../UnfoldingItem/UnfoldingItem";
import useIsMobile from "../../custom-hooks/useIsMobile";

const IntellectualOutputsMain = () => {
  const isMobile = useIsMobile();

  const hasScrolled = useScroll();
  return (
    <div
      style={
        hasScrolled === false ? { marginTop: "90px" } : { marginTop: "70px" }
      }
      className="intellectual-output-main__container"
    >
      <FirstSection
        title={"Work Packages"}
        text={
          "This section of the website presents the main results. AIVET will draw on the databases compiled by several VET stakeholders to produce intelligence of the local labour markets of VET graduates in Barcelona, Sofia and Vienna (WP2 and WP3). It will triangulate this information with qualitative evidence on the trajectories of VET graduates and the expectations of employers (WP4 and WP5). The project will also design training activities for the analysts of the VET stakeholders that use these databases (WP6). Management is WP1 but the outputs of this task are not public."
        }
      />
      <div
        style={
          !isMobile
            ? {
                paddingTop: "5rem",
                paddingBottom: "5rem",
                paddingLeft: "5rem",
                paddingRight: "5rem",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }
            : {
                paddingTop: "5rem",
                paddingBottom: "5rem",
                paddingLeft: "2rem",
                paddingRight: "2rem",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }
        }
      >
        <UnfoldingItem title={"Work Package 1"} text={"Management"} />
        <UnfoldingItem
          title={"Work Package 2"}
          text={
            "WP2 will review the available intelligence in the datasets that the VET stakeholders have set up in order to carry out their regular activities. As a source of information, these sources are inevitably biased towards the interests of certain organisations. However, they provide hands-on information that might help the curators of the database as well as other VET stakeholders. The work package will also test which statistical models are more reliable to analyse this information. Basically, these analyses will explore statistical associations between levels of education, internships, graduation and jobs, although different databases capture these dimensions in variable ways. In fact, the models provide a further test of the usability of the data. Additionally, the work package will conduct a literature review of studies of the local labour market of VET graduates in the three cities. "
          }
        />
        <UnfoldingItem
          title={"Work Package 3"}
          text={
            "The specific objective of WP3 lies in developing a well-informed VET skills intelligence AI tool at the local level. Precisely, this activity will design the tool. Artificial intelligence will expand the informational basis and the knowledge of local skills intelligence about VET graduates. By exploiting the existing databases with these instruments, it will be possible to get the most out of the relevant models that can capture the statistical associations between education, internships and professional development. At the same time, this tool will be usable for a variety of analysts who will be able to ask their own questions directly to the databases. This WP will deliver a prototype of an AI tool that can be applied to the databases developed by the partners and associated partners of AIVET. The project will also conduct a Delphi poll with the uses of these databases, who will reflect on the potential and the challenges of the resulting labour market intelligence. "
          }
        />
        <UnfoldingItem
          title={"Work Package 4"}
          text={
            "In social sciences, triangulation consists of using several research methods to explore the same phenomenon. Thus, AIVET will look at the labour markets of VET graduates through the lens of quantitative databases and qualitative, semi-structured interviews. While schools, public employment services, employers and NGOs store databases that generate variables such as the education level of the graduates, the length of their internship experience and their past and current jobs, the interviews will capture how individuals tell the story of their trajectory and how they have learnt certain skills. Triangulation will be instrumental to finding regularities in the whole set of quantitative and qualitative data."
          }
        />
        <UnfoldingItem
          title={"Work Package 5"}
          text={
            "In social sciences, triangulation consists of using several research methods to explore the same phenomenon. Rigorous analyses of the available databases may find out which employers hire the graduates from certain branches of VET. The relationship is relatively univocal in certain sectors such as energy, construction, industry and health, but more intricate in other ones such as IT, tourism and hospitality. However, this quantitative data cannot capture how the employers appraise and apply the potential of VET graduates. Instead, focus groups can account for the perspective of these employers by asking them to discuss the labour market in small groups of about five people."
          }
        />
        <UnfoldingItem
          title={"Work Package 6"}
          text={
            "WP2, 3, 4 and 5 have foreseen a dissemination strategy each. These strategies have to do with contacting participants and circulating small pieces of results. Work package 6 will take stock of these previous activities to produce a more systematic narrative that reaches a wider audience and remains meaningful after the closure of the project. Work package 6 is about storytelling and synthesis. The activities of WP6 will compile, articulate, digest, discuss and disseminate the conclusions, innovations and findings of the previous WPs in a plain way that is easily understandable for all the target groups. In order to reach meaningful conclusions that are relevant for diverse types of VET analysts (and other stakeholders), the consortium will invite the partners and associated partners as well as the participants in the WP3 workshop and Delphi poll, in the WP4 interviews and the WP5 focus groups to contribute to these activities. Remarkably, this invitation will showcase to what extent the stages of the workflow are integrated."
          }
        />
      </div>
    </div>
  );
};

export default IntellectualOutputsMain;
