import React, { useState } from "react";
import { Link } from "react-router-dom";

//CUSTOM-HOOKS
import useScroll from "../../custom-hooks/useScroll";
import useIsMobile from "../../custom-hooks/useIsMobile";
//IMATGES
import logo from "../../assets/miniLogo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";

//ESTILS
import "./Navbar.css";

const Navbar = ({ setIsClicked, isClicked }) => {
  const isMobile = useIsMobile();
  const hasScrolled = useScroll();
  const [displayMenu, setDisplayMenu] = useState(false);
  if (!isMobile) {
    return (
      <div
        className={`navbar__container ${
          hasScrolled ? "scrolled" : "not-scrolled"
        }`}
      >
        <Link to={"/"} style={{ padding: "0" }}>
          <img src={logo} alt="" className="navbar__image" />
        </Link>
        <div className="navbar__right-wrapper">
          <Link to="/" className="navbar__link">
            Home
          </Link>
          <Link to="/team" className="navbar__link">
            Team
          </Link>
          <Link to="/outputs" className="navbar__link">
            Work Packages
          </Link>
          <Link
            to="/blog"
            className="navbar__link"
            onClick={() => {
              if (isClicked) {
                setIsClicked(false);
              }
            }}
          >
            Blog
          </Link>
        </div>
      </div>
    );
  } else if (isMobile) {
    return (
      <div className="hamburger-button__container">
        <img src={logo} alt="blearn" className="navbar__image" />
        {!displayMenu && (
          <GiHamburgerMenu
            size={30}
            onClick={() => {
              setDisplayMenu(true);
            }}
          />
        )}
        {displayMenu && (
          <MdClose
            size={30}
            onClick={() => {
              setDisplayMenu(false);
            }}
          />
        )}
        {displayMenu && (
          <div className="menu-mobile__container">
            <Link
              to="/"
              className="navbar__link"
              onClick={() => {
                setDisplayMenu(false);
              }}
            >
              Home
            </Link>
            <Link
              to="/team"
              className="navbar__link"
              onClick={() => {
                setDisplayMenu(false);
              }}
            >
              Team
            </Link>
            <Link
              to="/outputs"
              className="navbar__link"
              onClick={() => {
                setDisplayMenu(false);
              }}
            >
              Work Packages
            </Link>
            <Link
              to="/blog"
              className="navbar__link"
              onClick={() => {
                setDisplayMenu(false);
                if (isClicked) {
                  setIsClicked(false);
                }
              }}
            >
              Blog
            </Link>
          </div>
        )}
      </div>
    );
  }
};

export default Navbar;
