import React, { useState } from "react";

//ESTILS
import "./UnfoldingItem.css";

const UnfoldingItem = ({ teachers, title, text }) => {
  const [isClicked, setIsClicked] = useState(false);

  return (
    <div
      className={
        !isClicked
          ? "unfoldingItem__container"
          : "unfoldingItem__container clicked"
      }
      onClick={() => {
        setIsClicked(!isClicked);
      }}
    >
      <div className="unfoldingItem__wrapper">
        <span className="unfoldingItem__wrapper-title">{title}</span>
        <div className="unfoldingItem__icon">+</div>
      </div>
      {isClicked && teachers !== undefined && (
        <>
          {teachers?.map((item) => {
            return (
              <p>
                <span className="unfoldingItem__teacher-name">{item.name}</span>
                {item.p1}
                {item.name === "Xavier Rambla" && (
                  <>
                    <a
                      href={item.link1}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.link1}
                    </a>
                    <span> {item.p2}</span>
                    <a
                      href={item.link2}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      {item.link2}
                    </a>
                  </>
                )}
                {item.name === "Rosario Scandurra" && (
                  <>
                    <span>{item.p2}</span>
                    <a
                      href={item.link1}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      here
                    </a>
                    <span> {item.p3}</span>
                    <a
                      href={item.link2}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      {item.link2}
                    </a>
                  </>
                )}
                {item.name === "Dr. Yuri Kazepov " && (
                  <>
                    <span>{item.p2}</span>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      {item.link}
                    </a>
                  </>
                )}
                {item.name === "Narcís Bosc " && (
                  <>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.link}
                    </a>
                  </>
                )}
                {item.name === "Pepka Boyadjieva " && (
                  <>
                    <span style={{ fontFamily: "Arvo, italic" }}>
                      {item.italic}
                    </span>
                    <span>{item.p2}</span>
                  </>
                )}
              </p>
            );
          })}
        </>
      )}
      {isClicked && text !== undefined && (
        <>
          <p>{text}</p>
        </>
      )}
    </div>
  );
};

export default UnfoldingItem;
