import React from "react";
import IntellectualOutputsItem from "./IntellectualOutputsItem/IntellectualOutputsItem";
import "./IntellectualOutputs.css";
const IntellectualOutputs = () => {
  return (
    <div className="intellectual-outputs__container">
      <div className="intellectual-outpots__top-wrapper">
        <p className="intellectual-outputs__title">Work Packages</p>
        <div className="intellectual-outputs__item-wrapper">
          <IntellectualOutputsItem
            title={"WP2"}
            text={
              "WP2 will review the available intelligence in the datasets that the VET stakeholders have set up in order to carry out their regular activities."
            }
          />
          <IntellectualOutputsItem
            title={"WP3"}
            text={
              "The specific objective of WP3 lies in developing a well-informed VET skills intelligence AI tool at the local level."
            }
          />
          <IntellectualOutputsItem
            title={"WP4"}
            text={
              "In social sciences, triangulation consists of using several research methods to explore the same phenomenon. Thus, AIVET will look at the labour markets of VET graduates through the lens of quantitative databases and qualitative, semi-structured interviews."
            }
          />
          <IntellectualOutputsItem
            title={"WP5"}
            text={
              " Rigorous analyses of the available databases may find out which employers hire the graduates from certain branches of VET."
            }
          />
          <IntellectualOutputsItem
            title={"WP6"}
            text={
              "WP2, 3, 4 and 5 have foreseen a dissemination strategy each. These strategies have to do with contacting participants and circulating small pieces of results."
            }
          />
        </div>
      </div>
      <p></p>
    </div>
  );
};

export default IntellectualOutputs;
