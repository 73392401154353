import React from "react";
import "./TeamMain.css";
//COMPONENTS
import FirstSection from "../FirstSection/FirstSection";
import UnfoldingItem from "../UnfoldingItem/UnfoldingItem";

//CUSTOM HOOKS
import useScroll from "../../custom-hooks/useScroll";
import { teamData } from "../../data/teamData";

const TeamMain = () => {
  const hasScrolled = useScroll();
  return (
    <div
      style={
        hasScrolled === false ? { marginTop: "90px" } : { marginTop: "70px" }
      }
    >
      <FirstSection
        text={
          "Universitat Autònoma de Barcelona leads a team including five partners and five associated partners. Besides UAB, the other partners are the Bulgaria Academy of Sciences, the Council of the Catalan Chambers of Commerce, the Free University of Brussels and the University of Vienna. The associated partners are Barcelona Activa, Education- Sofia, Molengeek (Brussels), Open Society Foundation- Sofia and Statistik Austria."
        }
        title={"Team"}
      />
      <div className="teamMain__wrapper">
        <UnfoldingItem
          title={"UAB: Universitat Autonoma de Barcelona"}
          teachers={teamData.uab}
        />
        <UnfoldingItem
          title={"BAS: Bulgarian Academy of Sciences"}
          teachers={teamData.bas}
        />
        <UnfoldingItem
          title={"UNIVIE: University of Vienna"}
          teachers={teamData.univie}
        />
        <UnfoldingItem
          title={"VUB: Vrije Universiteit Brussels"}
          teachers={teamData.vub}
        />
        <UnfoldingItem
          title={"Cambra de Comerç de Barcelona"}
          teachers={teamData.cambra}
        />
      </div>
    </div>
  );
};

export default TeamMain;
