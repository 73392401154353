import React, { useEffect } from "react";
import commentBox from "commentbox.io";
import "./CommentBox.css";
const CommentBox = ({ postId }) => {
  useEffect(() => {
    const removeCommentBox = commentBox("5729799662206976-proj", {
      className: "commentbox", // Classe CSS per a la caixa de comentaris
      // Altres opcions com backgroundColor, textColor, etc.
    });

    return () => {
      removeCommentBox();
    };
  }, []);
  return <div className="commentbox" id={postId} />;
};

export default CommentBox;
