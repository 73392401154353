import React from "react";
import "./BlogCard.css";

const BlogCard = ({ title, text, image, setIsClicked, setContent, postId }) => {
  const handleClick = () => {
    setIsClicked(true);
    setContent({
      title: title,
      text: text,
      image: image,
      postId: postId,
    });
  };
  return (
    <div className="blog-card__container">
      {image && <img src={image} alt="blog-card" />}
      <div className="blog-card__text-wrapper">
        <h3
          onClick={() => {
            handleClick();
          }}
        >
          {title}
        </h3>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </div>
  );
};

export default BlogCard;
