import React from "react";

//COMPONENTS
import FirstSection from "../FirstSection/FirstSection";
import Team from "../Team/Team";

//CUSTOM HOOKS
import useScroll from "../../custom-hooks/useScroll";
import IntellectualOutputs from "../IntellectualOutputs/IntellectualOutputs";

const Home = () => {
  const hasScrolled = useScroll();
  return (
    <div
      style={
        hasScrolled === false ? { marginTop: "90px" } : { marginTop: "70px" }
      }
    >
      <FirstSection
        text={
          "Developing AI tools for sharing intelligence on the local labour markets of VET graduates (AIVET). Project AIVET is Erasmus+ Cooperation Partnership 2023-1-ES01-KA220-VET-000153882. Between 2023 and 2026, AIVET is piloting a tool that helps analysts to inform VET local decision-makers with skills intelligence driven by AI in Barcelona, Sofia and Vienna. Interviews with graduates and focus groups with employers will triangulate this information. The conclusions will be discussed in participatory workshops and a training activity. An institute of statistics and several VET providers will participate in design, research and participatory activities as associated partners in the three cities."
        }
      />
      <Team />
      <IntellectualOutputs />
    </div>
  );
};

export default Home;
