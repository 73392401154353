import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import IntellectualOutputsMain from "./components/IntellectualOutputsMain/IntellectualOutputsMain";
import Navbar from "./components/Navbar/Navbar";
import TeamMain from "./components/TeamMain/TeamMain";
import Contact from "./Contact/Contact";
import Blog from "./components/Blog/Blog";

function App() {
  const [isClicked, setIsClicked] = useState(false);
  return (
    <>
      <Navbar setIsClicked={setIsClicked} isClicked={isClicked} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/team" element={<TeamMain />} />
        <Route path="/outputs" element={<IntellectualOutputsMain />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/blog"
          element={<Blog setIsClicked={setIsClicked} isClicked={isClicked} />}
        />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
