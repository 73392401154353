export const teamData = {
  uab: [
    {
      name: "Xavier Rambla",
      p1: " is Professor of Sociology at the Autonomous University of Barcelona (UAB). AIVET draws on his recent research on lifelong learning policies and young- adult students’ life courses in the European Union in projects YOUNG_ADULLLT ",
      link1: "https://www.young-adulllt.eu/",
      p2: "and CLEAR ",
      link2: "https://clear-horizon.eu/",
    },
    {
      name: "Carla Quesada ",
      p1: "is Associate Professor of Research Methods in Education at the Autonomous University of Barcelona (UAB). Her participation in AIVET drives from her research expertise on lifelong learning evaluation and how it impacts on workplace learning.",
    },
    {
      name: "Rosario Scandurra",
      p1: "I am a quantitative sociologist of education examining the nexus of inequality, education, skills formation and labour market transitions. My work contributes to education and public policy debates on educational opportunities, youth transitions and education segregation. My research has examined educational and skills inequalities and how these inequalities are accumulated during the life course. Multiple questions emerged from this work concerning the complementary sequences of effects embedded in individual contexts of skills formation.",
      p2: "Currently I am Ramón y Cajal Senior Researcher at the Open University of Catalonia, a member of the Globalisation, Education and Social Policies (GEPS) research centre, a researcher in the inter-university network Grupo de Investigación en Políticas Educativas (GIPE), since 2021 a member of the research group in Global Studies of the Universidade Aberta (Lisbon). I have been involved in more than 20 international projects. Pieces of my work have been published in a range of high-ranking journals and they could be found",
      link1: "https://scholar.google.com/citations?user=ksk8LGoAAAAJ&hl=es",
      p3: ". AIVET draws on my experience in project EPD- Employability in Programme Development",
      link2: "https://www.gla.ac.uk/research/az/epd/",
    },
  ],
  vub: [
    {
      name: "Johan Loeckx ",
      p1: " started as a micropreneur when he was 16, designing, selling and maintaining software for lawyer’s offices (1996). He received a MSc. in Electronic Engineering (2003), a MSc. in AI (2004) and a PhD entitled “Methods for Simulating and Analysing the Effects of EMC on Integrated Circuits” in 2010 from K.U.Leuven, Belgium. He co-designed the encryption system for securely exchanging patient information in the Belgian healthcare sector, for which a patent was filed by the Belgian government. In 2012, he was one of the co-founders of the second Flemish Freinet school in Brussels. He is an active musician.",
      p2: " Johan is currently assistant professor and lab manager at the Artificial Intelligence Lab at the Vrije Universiteit Brussels, Belgium, responsible for the R&D strategy and business development.",
    },
    {
      name: "Corneliu Cofaru ",
      p1: "holds a MSc degree in computer science from Transilvania University, 2006 and a PhD degree in computer science from Gent University, 2012. Prior to joining the AI Lab in 2020 he worked as an AI engineer, consultant and open source developer for several startups. In November 2020 he joined the AI Lab as a post-doctoral researcher in the Applied Research group, working on knowledge-driven AutoML approaches.",
    },
  ],
  univie: [
    {
      name: "Dr. Yuri Kazepov ",
      p1: "is a professor of International Urban Sociology and Compared Welfare Systems at the University of Vienna (Austria) from March 2015. Previously he has been teaching at the University of Urbino (Italy). He has been Jean Monet Fellow at the European University Institute (1995-96) visiting professor at the University of Bremen, Lund, Växjö, Leuven and ARC distinguished fellow at the Graduate Center CUNY. He is a founding member of the Network for European Social Policy Analysis (ESPAnet) and the past president of RC21, the Research Committee on Urban and Regional development of the International Sociological Association. His fields of interest are urban governance, citizenship and urban quality of life, social and educational policies in comparative perspective. On these issues he has been carrying out comparative research and evaluation activities for the European Commission (DG Research, DG Culture, DG Employment) and many National Agencies (NWO, Nicis, FTSE, CNR). He has been international coordinator of the following EU projects: EUREX (Minerva) the E-Urbs (Virtual Campus) and SEFIRA projects and the national partner of the following projects URBEX (FP4), GOETE, IMPROVE, INSPIRES, YOUNG ADULLLT, COHSMO. He was organiser of the 20th Anniversary ESPAnet Conference in Vienna. Website:",
      link: "https://bit.ly/3DEnGo7",
    },
  ],
  bas: [
    {
      name: "Pepka Boyadjieva ",
      p1: "is a Professor at the Institute of Philosophy and Sociology at the Bulgarian Academy of Sciences and an Honorary Professor of Sociology of Education at the University of Nottingham. Her research interests are in the field of higher education and lifelong learning with an emphasis on educational inequalities and social justice, university as an institution, and school-to-work transitions. She obtained a Fulbright New Century Scholars Fellowship (2008) and two Andrew Mellon Fellowships. Boyadjieva is a member of the Editorial Board of the International Journal of Lifelong Education, the British Educational Research Journal, the Journal of Social Science Education, and the Bulgarian Journal Sociological problems. She received the 2022 Cyril O. Houle Award for Outstanding Literature in Adult Education for the book (co-authored with Petya Ilieva-Trichkova): ",
      italic:
        "Adult Education as Empowerment: Re-imagining Lifelong Learning through the Capability Approach, Recognition Theory and Common Goods Perspective. ",
      p2: " Palgrave Macmillan.",
    },
    {
      name: "Svetlomir Zdravkov ",
      p1: " holds a PhD in Sociology from Sofia University “St. Kliment Ohridski”. In the period 2017 and 2020 he has been working as qualitative and quantitative researcher in the Research Center for Social Studies (Sofia University) working on “Educational Inequalities and Social Chances – Strategic Goals of the Reform of Bulgarian Secondary Education and Practical Results”, funded by The Bulgarian National Science Fund (BNSF). Svetlomir made a post-doc in the project “Dynamics of inequalities in participation in higher and adult education: A comparative social justice perspective”. He has participated in many multidisciplinary research projects as researcher and project coordinator and has developed different innovative instruments for big data analysis and statistical automation. His interests are in the field of educational inequalities, digital inequalities, market studies, knowledge economy and technological innovations, big data research. Currently Svetlomir Zdravkov is senior assistant at the Institute of Philosophy and Sociology at the Bulgarian Academy of Sciences.",
    },
  ],
  cambra: [
    {
      name: "Narcís Bosc ",
      p1: "Narcís Bosc. Born in Barcelona in 1960. Economist. CEO of the General Council of Chambers of Commerce of Catalonia since 2002. The GCCCC coordinates the activities and represents all the Catalan Chambers of Commerce.  Among its functions is the preparation of position papers on future regulations and economic policy, Internacionalitzation of catalan companies coordinating de design and implementation of the Catalan Enterprises Internationalization Plan and the administration of Vocational Training Internships and Apprenticeships. Representing businesses interests, is member of various Boards and Councils such as the Catalan Water Agency; Retail, Craftsmanship and Fashion Consortium; and Vocational Training Council. Secretary General of Respon.cat a business association promoting Corporate Social Responsibility.",
      link: "https://es.linkedin.com/pub/narc%C3%ADs-bosch-andreu/31/4ba/624",
    },
  ],
};
