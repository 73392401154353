import React from "react";
import "./IntellectualOutputsItem.css";
import { Link } from "react-router-dom";
const IntellectualOutputsItem = ({ title, text }) => {
  return (
    <Link
      className="intellectuals-outputs-item__container__wrapper"
      to={"/outputs"}
    >
      <div className="intellectual-outputs-item__container">
        <p className="intellectual-outputs-item__title">{title}</p>
        <p className="intellectual-outputs-item__text">{text}</p>
      </div>
    </Link>
  );
};

export default IntellectualOutputsItem;
