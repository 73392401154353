import React from "react";
import "./BlogEntry.css";
import CommentBox from "./CommentBox/CommentBox";
const BlogEntry = ({ title, image, text, postId, setDisplayFooter }) => {
  return (
    <div className="blog-entry__container">
      <h2>{title}</h2>
      <img src={image} alt="" />
      <p dangerouslySetInnerHTML={{ __html: text }} />
      <CommentBox postId={postId} />
    </div>
  );
};

export default BlogEntry;
