import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./Contact.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_btuncjc", // Your EmailJS service ID
        "contact_form", // Your EmailJS template ID
        formData,
        "QZ029_XPQiOlBpTBp" // Your EmailJS user ID
      )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        alert("Email sent successfully!");
        setFormData({ name: "", email: "", message: "" });
      })
      .catch((error) => {
        console.log("FAILED...", error);
        alert("Failed to send email.");
      });
  };

  return (
    <form className="contact-form" onSubmit={handleSubmit}>
      <div className="contact-form__wrapper">
        <div style={{ width: "46%" }}>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="contact-form__input"
            placeholder="Name"
          />
        </div>
        <div style={{ width: "46%" }}>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="contact-form__input"
            placeholder="Email"
          />
        </div>
      </div>
      <div className="contact-form__bottom-wrapper">
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
          className="contact-form__textarea"
          placeholder="Message"
          style={{ height: "90%" }}
        />
        <button type="submit" className="contact-form__button">
          Submit
        </button>
      </div>
    </form>
  );
};

export default Contact;
