import React from "react";

//IMATGES
import mockImage from "../../assets/EuLogo.png";
import erasmusPlus from "../../assets/erasmus.png";
//ESTILS
import "./Footer.css";
import Button from "../Button/Button";
const Footer = () => {
  return (
    <div className="footer__container">
      <div className="footer__button-wrapper">
        <Button text={"Contact"} color={"#1d3561"} route={"/contact"} />
      </div>
      <img src={mockImage} alt="" className="footer-logo" />
      <img src={erasmusPlus} alt="" className="footer-logo" />
    </div>
  );
};

export default Footer;
