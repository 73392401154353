import React from "react";

//IMATGES
import logo from "../../assets/Logo_AIVET_-_blau.png";

//ESTILS
import "./FirstSection.css";

const FirstSection = ({ text, title }) => {
  return (
    <div className="firstSection__container">
      <div className="firstSection__wrapper">
        {title !== undefined && <span>{title}</span>}
        <p>{text}</p>
      </div>

      <img src={logo} alt="mockLogo" className="first-section__logo" />
    </div>
  );
};

export default FirstSection;
